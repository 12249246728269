import { render, staticRenderFns } from "./wasteVolumnManage.vue?vue&type=template&id=4eb34f77"
import script from "./wasteVolumnManage.vue?vue&type=script&lang=js"
export * from "./wasteVolumnManage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\SHMS\\FrontEnd\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4eb34f77')) {
      api.createRecord('4eb34f77', component.options)
    } else {
      api.reload('4eb34f77', component.options)
    }
    module.hot.accept("./wasteVolumnManage.vue?vue&type=template&id=4eb34f77", function () {
      api.rerender('4eb34f77', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/env/waste/wasteVolumnManage.vue"
export default component.exports